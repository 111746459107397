.profile-hide-pointer {
    pointer-events: none;
    /* top: 37%;
    left: 20%; */
    left: 20vw;
    top: 37%;
    height: 11.9vw;
    width: 11.9vw;
    z-index: 20;
}

.ping-div {
    left: 20vw;
    top: 37%;
    height: 11.9vw;
    width: 11.9vw;
    z-index: 0;
    opacity: 0.2;
}